<template>
    
    <form action @submit.prevent="verifyCode">
        <div class="d-flex flex-column justify-content-between text-dark-gray validate-form__size" style="gap: 43px;">
            <div class="d-flex flex-column" style="gap: 43px;">
                <div class="text-center f-18 mx-auto validate-form-text__size">
                    <p>
                        Se ha enviado el código de verificación a su correo para generar una nueva contraseña
                    </p>
                </div>  
                <div class="text-center f-18">
                    <p>
                        {{ hidden_email }}
                    </p>
                </div> 
                <div>
                    <CodeInput :loading="false" type="text" class="verification-code w-100" :fields="4" @complete="onComplete"/>
                    <div class="w-80 mx-auto mt-2">
                        <vee-error :text="codeError" class="w-80" />
                    </div>
                </div>
                <div class="d-flex justify-content-center custom__mb">
                    <button class="btn btn-general px-5 f-600" type="submit">Aceptar</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    middleware: [
        'guest',
        'validate-code'
    ],
    layout:'login',
    data(){
        return {
            code: '',
            codeError: '',
        }
    },
    computed:{
        ...mapGetters({
            hidden_email: 'auth/hidden_email'
        })
    },
    methods:{
        verifyCode(){
            this.$try(async () => {
                if(this.code.length < 4) return this.codeError = "Complete todos los campos"

                const data = await this.$store.dispatch('auth/VALIDATE_CODE', { codigo: this.code });

                if(data?.error) return this.codeError = data.error;

                this.navigateTo({ name: 'validate.password' });
            });
        },
        onComplete(code){
            this.$try(() => {
                this.code = code;
            });
        }
    }
}
</script>